// This module is meant to provide access to the `@host` module API.  It determines if it is able to
// use the API that exists globally, or if it should export the modules directly (causing them to be
// bundled with the main app).

// This facilitates the deferred loading mechanism.

import { WINDOW_ID } from "./constants";

const frameApi = (process.browser
    ? window[WINDOW_ID]
    : require("./frame.api") as typeof import("./frame.api")
);

const {
    initialData,
    hostQuery,
    hostUrl,
    query,
    referrerUrl,

    frameHandler,
    frameMeta,
    events,
    persistCommands,

    Sentry,
    errorHandler,
    ga,
    mfq,
    triggerEvent,
    userEngaged,
} = frameApi;

export * from "./constants";

export {
    initialData,
    hostQuery,
    hostUrl,
    query,
    referrerUrl,

    frameHandler,
    frameMeta,
    events,
    persistCommands,

    Sentry,
    errorHandler,
    ga,
    mfq,
    triggerEvent,
    userEngaged,
};