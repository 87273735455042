import { StateModel, StrictState } from "@autocorp/redux";

export interface IProductsState {
    creditTool: boolean;
    tradeTool: boolean;
    lenderTool: boolean;
}

const defaultProductsState: StrictState<IProductsState> = {
    creditTool: false,
    tradeTool: false,
    lenderTool: false,
};

export const ProductsModel = new StateModel({
    name: "products",
    state: defaultProductsState,
});
