import { StateModel, StrictState } from "@autocorp/redux";
import { frameHandler } from "@host";
import { getCampaignOutput } from "@util/campaign";
import { mfq } from "@host";
import type { LeadCampaignInputType, GetWidgetDetailsQuery } from "@graphql/schema";

export interface IDealerState {
    companyId?: string;
    /** @deprecated use `companyId` */
    widgetId: string;
    ctaId?: string;
    variant?: string;
    widget?: GetWidgetDetailsQuery["getWidgetDetails"],
    sourceUrl: string;
    campaign?: LeadCampaignInputType;
}

const defaultDealerState: StrictState<IDealerState> = {
    companyId: IS_PRODUCTION ? frameHandler.company : (
        frameHandler.company || COMPANY_ID || ""
    ),
    widgetId: IS_PRODUCTION ? frameHandler.widgetId : (
        frameHandler.widgetId || WIDGET_ID || ""
    ),
    ctaId: "",
    variant: "",
    widget: undefined,
    sourceUrl: frameHandler.sourceUrl,
    campaign: getCampaignOutput(frameHandler.campaign),
};

export const DealerModel = new StateModel({
    name: "dealer",
    state: defaultDealerState,
    skipReset: true,
    updaters: {
        companyId: (newVal, state) => {
            if (newVal !== state.companyId) mfq.set("company", newVal);
            return {};
        },
    },
});