import _slicedToArray from "/vercel/path0/common/temp/node_modules/.pnpm/@babel+runtime@7.15.4/node_modules/@babel/runtime/helpers/esm/slicedToArray";
import _defineProperty from "/vercel/path0/common/temp/node_modules/.pnpm/@babel+runtime@7.15.4/node_modules/@babel/runtime/helpers/esm/defineProperty";
var utmKeys = ["utm_campaign", "utm_content", "utm_medium", "utm_source"];
var campaignKeys = new Array(5).fill("").map(function (_, i) {
  return "s".concat(i + 1);
}).concat(utmKeys);
export var getCampaignInput = function getCampaignInput() {
  var obj = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var keyRef = Object.keys(obj).reduce(function (acc, key) {
    return Object.assign(acc, _defineProperty({}, key.toLowerCase(), key));
  }, {});
  return campaignKeys.reduce(function (acc, key) {
    var realKey = keyRef[key];
    return realKey ? Object.assign(acc, _defineProperty({}, key, obj[realKey])) : acc;
  }, {});
};

var toCamelCase = function toCamelCase(key) {
  return key.replace(/_(.)/g, function (m, p1) {
    return p1.toUpperCase();
  });
};

export var getCampaignOutput = function getCampaignOutput() {
  var campaign = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return Object.entries(campaign).reduce(function (acc, _ref) {
    var _ref2 = _slicedToArray(_ref, 2),
        key = _ref2[0],
        val = _ref2[1];

    return Object.assign(acc || {}, _defineProperty({}, toCamelCase(key), val));
  }, undefined);
};