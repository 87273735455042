import {
    ApiClient,
    IApiClientArgs,
    QueryDefs,
    QueryType,
    ClientQueries,
} from "@autocorp/ava/esm/api";
import { errorHandler } from "@host";
import {
    GetWidgetDetailsQuery,
    GetWidgetDetailsQueryVariables,
    SendNotificationMutation,
    SendNotificationMutationVariables,
} from "@graphql/schema";

import { GetWidgetDetails } from "./query/widget";
import { SendNotification } from "./query/lead";

export { gql } from "graphql-request";
export type { QueryDefs, QueryType };

type RootQueries = QueryDefs<{
    getWidgetDetails: QueryType<
        GetWidgetDetailsQuery,
        GetWidgetDetailsQueryVariables
    >;
    sendNotification: QueryType<
        SendNotificationMutation,
        SendNotificationMutationVariables
    >;
}>;

export const rootApi = new ApiClient<RootQueries>({
    apiUrl: API_URL,
    production: IS_PRODUCTION,
    dedupeLeads: !IS_PRODUCTION && DEDUPE,
    mockProfiles: TEST ? 0 : MOCK ? 1 : undefined,
    critical: {
        sendNotification: false,
    },
    errorHandler,
}, {
    getWidgetDetails: GetWidgetDetails,
    sendNotification: SendNotification,
});

export const mockProfile = rootApi.mockProfile;
export const rootApiClient = rootApi.getClient();

export const createNewClient = <
    TQueryDefs extends QueryDefs = QueryDefs,
>(
    queryDocs: ClientQueries<TQueryDefs>,
    options?: Partial<IApiClientArgs<TQueryDefs>>,
) => {
    return rootApi.extendClient<TQueryDefs, ClientQueries<TQueryDefs>>(
        queryDocs,
        options,
    );
};