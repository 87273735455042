export const WINDOW_ID = "FRAME_HANDLER";
export const IS_ERROR_INDICATOR = "IS_ERROR";

export const ANALYTICS_DEBUG_PARAM = "avaAnalyticsDebug";
export const MIXPANEL_READY = "mixpanelReady";
export const MOUSEFLOW_READY = "mouseflowReady";
export const GA_READY = "gaReady";

export type ALL_READY_KEYS = (
    | typeof MIXPANEL_READY
    | typeof MOUSEFLOW_READY
    | typeof GA_READY
);