export var mockActions = {
  cancel: Symbol("cancel")
};
export var mockDelay = function mockDelay(v) {
  var maxTimeDelay = 3000;
  var timeStart = new Date().valueOf();

  var delayXfer = function delayXfer(d) {
    return new Promise(function (resolve) {
      var timeEnd = new Date().valueOf();
      var timeDiff = (timeEnd - timeStart) / 1000; // Seconds elapsed

      if (timeDiff > maxTimeDelay) {
        resolve(d);
      }

      setTimeout(function () {
        resolve(d);
      }, maxTimeDelay - timeDiff);
    });
  };

  return delayXfer(v);
};