import {
    createStore,
    AnyStateModelCollection,
    StateOfModelCollection,
    StateModel,
    StoreInstance,
} from "@autocorp/redux";
import { mockProfile, rootApi } from "@api/api-client";
import { DealerModel } from "./dealer";
import { UserModel, IUserState } from "./user";
import { ProductsModel } from "./products";
import { DatalayerModel } from "./datalayer";

const rootModels = {
    UserModel,
    DealerModel,
    ProductsModel,
    DatalayerModel,
};

type StateOfRootModels = StateOfModelCollection<typeof rootModels>;

export const getCombinedModels = <T extends AnyStateModelCollection>(models: T) => ({
    ...rootModels,
    ...models,
});

export const hydrateModel = async <T extends StateModel<any>>(model: T, instance: StoreInstance<any>) => {
    if (MOCK || TEST) {
        const profile = mockProfile;
        console.log(TEST
            ? `Loading test profile for ${model.name}`
            : `Loading mock profile ${profile} for ${model.name}`,
        );

        const mockData = (await import(`./__fixtures/profile${profile}.json`)) || {};

        if (mockData.default.hasOwnProperty(model.name)) {
            const data = mockData.default[model.name];
            const modelApi = instance.model[model.name];
            modelApi?.merge(data as any);

            (model as any).defaultState = {
                ...model.defaultState,
                ...modelApi?.state || {},
            };
        }
    }
};

const rootStore = createStore(rootModels, {
    events: {
        postReset: () => {
            rootApi.reset();
        },
        postInit: async (instance) => {
            Object.values(rootModels).forEach((model) => {
                hydrateModel(model, instance);
            });
        },
    },
    enhancer: (
        !IS_PRODUCTION &&
        process.browser &&
        (window as any).__REDUX_DEVTOOLS_EXTENSION__ &&
        (window as any).__REDUX_DEVTOOLS_EXTENSION__()
    ) || undefined,
});

export const useStoreInstance = (preloadedState?: StateOfRootModels) => (
    rootStore.useStoreInstance(preloadedState, {
        fireEvents: process.browser,
    })
);

export const attachModel = rootStore.attach;

export const Provider = rootStore.Provider;
export const useRootStore = () => rootStore.useStore();
export const useRootModels = () => rootStore.useModel();

export type { IDealerState } from "./dealer";
export type { IUserState };
