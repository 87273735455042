import { gql } from "graphql-request";

export const GetWidgetDetails = gql`
    query getWidgetDetails(
        $widget: String!,
        $ctaId: String,
        $variant: String
    ) {
        getWidgetDetails(
            widgetId: $widget,
            ctaId: $ctaId,
            variant: $variant
        ) {
            company {
                id
                name
                widgetId
                archived
                products
            }
            group {
                id
                name
                legalDesc
                distributionType
            }
            memberCompanies {
                id
                name
                widgetId
                archived
                products
            }
            widgetOverrides
        }
    }
`;