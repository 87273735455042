import { h } from "preact";
import { useMemo } from "preact/hooks";

import "@style/global.scss";
import "@style/font-face.scss";
import "~/initial-setup";

import { Provider, useStoreInstance } from "@store";

import type {
    AppProps,
    TopLevelComponent,
    NextViewComponent,
} from "@model/view/internal";

type NextAppProps = AppProps<TopLevelComponent<NextViewComponent, any>>;

function App(props: NextAppProps) {
    const {
        Component,
        pageProps = {},
    } = props;

    const store = useStoreInstance(pageProps.preloadedState);
    if (process.browser && RUNTIME_DEBUG) (window as any).store = store;

    const RenderComponent = useMemo(() => {
        if (Component.Container) {
            return <Component.Container {...props} />;
        }

        return <Component {...pageProps} />;
    }, [Component, props, pageProps]);

    return (
        <Provider store={store}>
            {RenderComponent}
        </Provider>
    );
}

export default App;